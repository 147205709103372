var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mainCont position-relative" }, [
    _c(
      "div",
      { staticClass: "background" },
      [
        _c("PageButton", {
          attrs: {
            bottomCornerButtonList: _vm.bottomCornerButtonList,
            isLastStep: _vm.isLastStep,
          },
          on: {
            clickNext: _vm.nextPage,
            updateNumbers: function ($event) {
              return _vm.updateNumbers($event)
            },
          },
        }),
        _c(
          "div",
          { staticClass: "backgroundImg_n_DragArea" },
          [
            _c("img", {
              attrs: { id: "puzzlebackground", src: _vm.backImg.background },
            }),
            _vm._l(_vm.ObjectList, function (item) {
              return _c("div", { key: item.id }, [
                _c("div", {
                  class: "stroke" + item.id,
                  on: {
                    click: function ($event) {
                      return _vm.foundAnswer(item.id)
                    },
                  },
                }),
                _c("div", { staticClass: "images" }, [
                  _c("img", {
                    attrs: {
                      src:
                        _vm.selecteArr.indexOf(item.id) == -1
                          ? item.image1
                          : item.image2,
                    },
                  }),
                ]),
              ])
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }