<template>
  <div class="mainCont position-relative">
    <div class="background">
      <PageButton :bottomCornerButtonList="bottomCornerButtonList" :isLastStep="isLastStep" @clickNext="nextPage" @updateNumbers="updateNumbers($event)"></PageButton>
      <div class="backgroundImg_n_DragArea">
        <img id="puzzlebackground" :src="backImg.background" />
        <div v-for="item in ObjectList" :key="item.id">
          <div :class="'stroke' + item.id" @click="foundAnswer(item.id)" />
          <div class="images">
            <img :src="selecteArr.indexOf(item.id) == -1 ? item.image1 : item.image2" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";

export default {
  props: {
    ObjectList: {
      type: Array,
      require: true,
    },
    backImg: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 1,
            endNumberRange: 1,
          },
        ],
      },
      selecteArr: [],
      success_audio_sound: require("@/assets/audio/correct_answer.mp3"),
      answersfound: 0,
      socketInfo: {},
      foundAnswerValue: 0,
    };
  },
  components: {
    PageButton,
  },

  mounted() {
    this.$bus.$on("NumberClick", ({ id }) => {
      this.foundAnswer(id, true);
    });
  },
  destroyed() {
    this.$bus.$off("NumberClick");
  },
  watch: {
    socketInfo: {
      handler(value) {
        const data = {
          clickType: 20018,
          data: { value },
          text: "NumberClickGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    foundAnswer(id, isFromSocket = false) {
      setTimeout(() => {});
      console.log(id);
      if (!isFromSocket) {
        this.socketInfo = {
          id,
          value: Math.random(),
        };
      }
      if (this.selecteArr.indexOf(id) === -1) {
        this.selecteArr.push(id);
      }
        playCorrectSound(true, false);
      if (this.selecteArr.length === 5) {
        // this.nextButton = require("@/assets/img/04-Buttons/button-next.svg");
        startConfetti();
        playCorrectSound();
        this.isLastStep = true;
      }
    },
    nextPage() {
      if (this.selecteArr.length === 5) {
        this.$bus.$emit("nextButton", true);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background: #fff;
}

.objectsCont {
  align-self: center;
  margin-left: 10%;
  height: 80%;
  width: 80%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  align-content: space-evenly;
  //background-color: blue;
  .objects {
    width: 30%;
    height: 45%;
    // background-color: black;
    .objectImage {
      // width: 100%;
      height: 100%;
    }
    .card {
      height: 90%;
      width: 80%;
      background-color: #ffffff;
      border-bottom: 10px solid #cfc8b0;
      border-radius: 20px;
    }
    .style1 {
      transform: rotate(7deg);
      border-right: 3px solid #cfc8b0;
    }
    .style2 {
      transform: rotate(-7deg);
    }
  }
}
.objectDisplaycont {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(159, 150, 150, 0.4);
  top: 0;
  display: flex;
  flex-direction: row;

  .objects {
    position: relative;
    height: 45%;
    width: 55%;
    display: flex;
    flex-direction: row;
    align-self: center;
    margin-left: 20%;
    // background-color: black;

    .card {
      height: 90%;
      width: 80%;
      margin-left: 10%;
      background-color: #ffffff;
      border-top: 5px solid #cd4c3f;
      border-left: 5px solid #cd4c3f;
      border-right: 5px solid #cd4c3f;
      border-bottom: 10px solid #cd4c3f;

      border-radius: 20px;
      .objectImage {
        width: 100%;
      }
    }
    .style1 {
      transform: rotate(7deg);
      //  border-right: 3px solid #CFC8B0;
    }
    .style2 {
      transform: rotate(-7deg);
    }
  }

  .displayObjects {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
  }
}
.timer {
  position: absolute;
  top: 10%;
  height: 80%;
  left: 5%;
  .clock_cont {
    height: 85%;
    position: absolute;
    left: 31%;
    width: 40%;
    /* margin-left: -3px; */
    top: 4%;
    .clock {
      background-color: #f4d737;
      height: 100%;
      position: absolute;
      left: 0;
      width: 100%;
      /* margin-left: -3px; */
      bottom: 0;
    }
  }

  .time {
    z-index: 5;
    height: 100%;
  }
}

.bottonCont {
  bottom: 0;
  width: 50%;
  /* margin-top: 45%; */
  height: 150px;
  position: absolute;
  display: flex;
  justify-content: center;
  left: 25%;
  .redBackground {
    width: 100%;
    height: 50%;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    background-color: #d87065;
    position: absolute;
    bottom: 0;
  }
  .bottonsWrapper {
    display: flex;
    bottom: 0;
    height: -moz-fit-content;
    justify-content: center;
    height: 100%;
    width: 78%;
    position: absolute;

    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    background-color: #ffffff;
    justify-content: space-evenly;
    align-items: center;
  }
}

.content-words-two {
  min-width: 100px;
  min-height: 100px;
  z-index: 1;
  border-radius: 15px;
  background-color: #cd4c3f;
  border-bottom: 8px solid #ac3d32;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    color: #fff;
    font-size: 25px;
  }
  span:nth-child(2) {
    color: #fff;
    font-size: 30px;
  }
}
.content-words-two:active {
  transform: scale(0.95);
}
.content-words-two:hover {
  min-width: 100px;
  min-height: 100px;
  border-radius: 15px;
  background-color: #912016;
  border-top: 8px solid #6e0c03;
  border-bottom: 0px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  span {
    color: #fff;
    font-size: 25px;
  }
  span:nth-child(2) {
    color: #fff;
    font-size: 30px;
  }
}

.txt-py {
  // very large screen
  font-size: 25px !important;
  padding-top: 10px;
  @media screen and (max-width: 2200px) {
    // external screen
    font-size: 25px !important;
    padding-top: 5px;
  }
  @media screen and (max-width: 1500px) {
    // laptop
    font-size: 20px !important;
  }
  @media screen and (max-width: 1300px) {
    // ipad
    font-size: 25px !important;
  }
}

.txt-hz {
  // very large screen
  font-size: 50px !important;
  @media screen and (max-width: 2200px) {
    // external screen
    font-size: 40px !important;
  }
  @media screen and (max-width: 1500px) {
    // laptop
    font-size: 40px !important;
  }
  @media screen and (max-width: 1300px) {
    // ipad
    font-size: 35px !important;
  }
}

.objectDisplay {
  position: absolute;
  width: 50%;
  bottom: 16%;
  left: 25%;
}
.plate {
  bottom: 10%;
  position: absolute;
  width: 60%;
  left: 20%;
}
.text_hanzi_top {
  width: 100%;
  height: 25%;
  left: 0;
  position: absolute;
  background: #224e96;
  text-align: center;
  color: #fff;
  .pinyin {
    margin-top: 20px;
    font-family: "STHeitiSCPinyin-Light";
  }
  :hover {
    background: #1b4589;
  }
}
.objListBackground {
  position: absolute;
  width: 100%;
}
.objectList {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 15%;
  height: 55%;
  //  background-image: url("../../../common/img/lesson3/5-DRAG_N_DROP_TO_PLATE/shape.svg");
  top: 17%;
  left: 10%;
  .strokeImage {
    width: 80%;
    height: 20%;
    -webkit-user-drag: auto;
    z-index: 10;
    margin-top: 25%;
    margin-left: 10%;
  }
}
.mainCont {
  width: 100%;
  // width: calc(100% - 14px);
  // height: calc(100% - 40px);
  height: 100%;
  // margin-top: 15px;
  // margin-left: 15px;
  // background-image: url("../../../common/img/lesson3/5-DRAG_N_DROP_TO_PLATE/background.svg");
  background: #fff;
  border-radius: 48px;
  position: relative;
  overflow: hidden;
}
.background {
  text-align: -webkit-center;
  position: absolute;
  height: 96%;
  width: 98%;
  top: 1%;
  left: 1%;
  background: #f9f6e6;
  border-radius: 48px;
  //  top: 15%;
  //   left: 15%;
  margin: auto;
  overflow: hidden;
}
.backgroundImg_n_DragArea {
  position: relative;
  width: fit-content;
  height: 100%;
  border-radius: 10%;
  max-width: 100%;
  #puzzlebackground {
    width: 100%;
  }

  img {
    position: relative;
    height: 100%;
    text-align: -webkit-center;
  }
}
.images {
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
  border-radius: 10%;
  img {
    position: relative;
    height: 100%;
    text-align: -webkit-center;
    width: 100%;
  }
}
// .find_and_next_button {
//   position: absolute;
//   right: -2.5%;
//   bottom: -1%;
//   width: 24%;
//   cursor: pointer;
//   z-index: 1001;
//   .nextButtonImage {
//     width: 90%;
//   }
// }

.strokesFoundBoxes {
  position: absolute;
  right: 11%;
  top: 0;
  height: fit-content;
  padding: 5px 25px 15px 25px;
  display: flex;
  flex-direction: row;
  background-color: #f4d737;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-bottom: 10px solid #d1b726;
  .strokesFoundimage {
    height: 100%;
    width: 27px;
    margin-top: 20%;
  }
  .outerBox {
    width: 100%;
    height: 100%;
    padding: 0px 4px;
    position: relative;
    display: flex;

    .innerBox {
      width: 80%;
      height: 80%;
      margin: 10%;
      border-radius: 15px;
      // background-color: #2472B3;
    }

    .innerBoxFound {
      width: 80%;
      height: 80%;
      margin: 10%;
      border-radius: 15px;
      // background-color: #F3D438;

      .strokesFoundimage {
        height: 65%;
        margin-top: 30%;
        // margin-left: 40%;
        margin-right: 5%;
      }
    }
  }
}
.star {
  display: none;

  img {
    // width: 20px;
    //  height: 20px;
    // display: none;

    &.animatedstar {
      display: block;
      -webkit-animation: spin 1s linear infinite;
      -moz-animation: spin 1s linear infinite;
      animation: spin 1s linear infinite;
      -webkit-animation-iteration-count: 1;
    }
  }
}
.stroke1 {
  display: block;
  position: absolute;
  width: 8%;
  height: 28%;
  top: 18%;
  left: 19%;
  //  background-color: black;
  z-index: 99;
  &:hover {
    cursor: pointer;
  }
}
.stroke2 {
  display: block;
  position: absolute;
  width: 13%;
  height: 28%;
  top: 18%;
  left: 44%;
  // background-color: black;
  z-index: 99;
  &:hover {
    cursor: pointer;
  }
}

.stroke3 {
  display: block;
  position: absolute;
  width: 11%;
  height: 26%;
  top: 17%;
  right: 19%;
  // background-color: black;
  z-index: 99;
  &:hover {
    cursor: pointer;
  }
}
.stroke4 {
  display: block;
  position: absolute;
  width: 14%;
  height: 26%;
  bottom: 25%;
  left: 28%;
  //background-color: black;
  z-index: 99;
  &:hover {
    cursor: pointer;
  }
}
.stroke5 {
  display: block;
  position: absolute;
  width: 13%;
  height: 26%;
  bottom: 25%;
  right: 26%;
  //  background-color: black;
  z-index: 99;
  &:hover {
    cursor: pointer;
  }
}
.stroke6 {
  display: block;
  position: absolute;
  //       background-color: black;
  z-index: 99;
  width: 34%;
  height: 34%;
  bottom: 0;
  right: 0;
  &:hover {
    cursor: pointer;
  }
}

.confettiCanvas {
  z-index: 900;
  position: absolute;
}
</style>
